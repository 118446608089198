
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { GoogleBusinessPlatformData, SupportedPlatform } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type GoogleBusinessMessagesSettings = Pick<GoogleBusinessPlatformData, 'enabled' | 'customAgentId'>;

@Component({
  name: 'google-business-messages-settings-card',
})
export default class GoogleBusinessMessagesSettingsCard extends mixins(BotMixin) {
  googleBusinessMessagesSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.googleBusinessMessagesSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBotPlatform({
        id: this.bot.id,
        platform: SupportedPlatform.GoogleBusiness,
        data: this.googleBusinessMessagesSettings,
      });
      this.$notify.success('Successfully updated Google Business Messages-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Google Business Messages-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.googleBusinessMessagesSettings = this.resetValue();
  }

  private resetValue(): GoogleBusinessMessagesSettings {
    return {
      enabled: this.bot.platformData.googleBusiness.enabled,
      customAgentId: this.bot.platformData.googleBusiness.customAgentId,
    };
  }
}
