
import GoogleBusinessMessagesSettingsCard from '@/components/google/GoogleBusinessMessagesSettingsCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-platform-google-business-messages',
  components: { GoogleBusinessMessagesSettingsCard },
})
export default class ProjectPlatformGoogleBusinessMessages extends mixins(ProjectViewMixin) {}
